import React from 'react';
import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';

const RowChart = ({ companyReporting }) => {
  const values = [
    {
      width: companyReporting['paid-within-30-days'],
      background: '#00a4ff',
      label: `${companyReporting['paid-within-30-days']}%  paid within 30 days`
    },
    {
      width: companyReporting['paid-within-31-60-days'],
      background: '#ffe13e',
      label: `${companyReporting['paid-within-31-60-days']}% paid within 31-60 days`
    },
    {
      width: companyReporting['paid-after-60-days'],
      background: '#fd6383',
      label: `${companyReporting['paid-after-60-days']}% paid after 60+ days`
    }
  ];

  const renderTooltip = ({ value, ...props }) => (
    <Tooltip id={`${value}-tooltip`} {...props} className="px-5">
      <p className="mb-0 py-2 px-5">{value}%</p>
    </Tooltip>
  );

  return (
    <>
      <h3 className="text-center mt-lg mb-0">When are invoices paid?</h3>

      <p className="text-center">split out into more detail by typical payment periods</p>

      <div className="Gradient-Container Row-Chart">
        <div className="d-flex">
        {values.map((val, idx) => (
          <OverlayTrigger
            placement="top"
            key={idx.toString()}
            overlay={(props) => renderTooltip({ value: val.width, ...props })}
          >
            <div
              style={{
                width: `${val.width}%`,
                background: val.background
              }}
              className="Chart-Div mx-2"
            />
          </OverlayTrigger>
        ))}</div>
      </div>

      <small className="d-block text-center mt-1">
        <strong>Percentages</strong>
      </small>

      <Row className="d-flex justify-content-center mt-4">
        {values.map((value, idx) => {
          return (
            <Col
              key={idx.toString()}
              md={4}
              xs={10}
            >
              <div className="Heading-Column">
                <div
                  className="Square-Indicator"
                  style={{ background: value.background }}
                />
                <span className="mt-1 Chart-Tooltip">{value.label}</span>
              </div>
            </Col>
          );
        })}
      </Row>
    </>
  );
};

export default RowChart;
