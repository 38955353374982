import React from 'react';
import { CookiesProvider } from 'react-cookie';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './assets/scss/app.scss';
import CookieModal from './components/common/CookieModal';
import ScrolltoTop from './helpers/ScrolltoTop';
import DetailsPage from './pages/DetailsPage';
import HomePage from './pages/HomePage';
import SearchList from './pages/SearchList';
import ThankYou from './pages/ThankYou';

function App() {
  return (
    <CookiesProvider>
      <Router>
        <ScrolltoTop />
        <Switch>
          <Route path="/company/:id" component={DetailsPage} />
          <Route exact path="/search" component={SearchList} />
          <Route exact path="/thank-you" component={ThankYou} />
          <Route path="/" component={HomePage} />
        </Switch>
        <CookieModal />
      </Router>
    </CookiesProvider>
  );
}

export default App;
