import React from 'react';
import DescriptionRow from './DescriptionRow';
import { getFormattedAddress } from '../../helpers/address';

const CompanyDescription = ({ companyDetails }) => {
  return (
    <div>
      <h2 className="text-center mt-lg">Company details</h2>

      <div className="Company-Container Gradient-Container">
        <DescriptionRow
          title="Registered company number"
          value={companyDetails.number}
        />
        <DescriptionRow
          title="Registered address"
          value={getFormattedAddress(companyDetails['registered-address'])}
        />
        {companyDetails.industries && companyDetails.industries.length > 0 && (
          <>
            <DescriptionRow
              title="Sector"
              value={companyDetails.industries[0].section}
            />
            <DescriptionRow
              title="Business activity"
              value={companyDetails.industries[0].description}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default CompanyDescription;
