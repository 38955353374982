import React from 'react';
import SearchInput from '../common/SearchInput';

const SearchComponent = ({ query, placeholder, id }) => {
  return (
    <SearchInput query={query} id={id || "search"} placeholder={placeholder} />
  );
};

export default SearchComponent;
