import React from 'react';
import { Col, Row } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { LIMIT } from '../constants/pagination';

const PaginationFooter = ({ handlePaginationChange, total, page }) => {
  return (
    <>
      <Row className="Pagination-Container">
        <Col md={12} className="text-center">
          <ReactPaginate
            previousLabel="Previous Page"
            disabledClassName="Display-None"
            previousLinkClassName="Button primary btn btn-primary"
            nextLinkClassName="Button primary btn btn-primary"
            nextLabel="Next Page"
            breakLabel="..."
            pageClassName="Pagination-Item"
            pageCount={Math.ceil(total / LIMIT)}
            onPageChange={(newPage) => handlePaginationChange(newPage)}
            containerClassName="pagination justify-content-center align-items-center"
            subContainerClassName="pages pagination"
            activeClassName="Pagination-Item active"
            breakLinkClassName="Pagination-Item"
            forcePage={page}
          />
        </Col>
      </Row>
    </>
  );
};

export default PaginationFooter;
