import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import CompanyWatchLogo from '../../assets/images/company-watch.png';
import CompanyWatchApi from '../../services/CompanyWatch.service';
import { toHumanString } from 'human-readable-numbers';

import './chart.scss';

const CWChart = () => {
    const { id } = useParams();
    const [data, setData] = useState({
        score: [],
        credit_limits: {
            currency: '',
            currentCreditLimit: '',
        },
        contract_limits: {
            currency: '',
            currentContractLimit: ''
        }
    });

    const [chartOptions, setChartOptions] = useState({
        chart: {
            height: "51.81%"
        },
        credits: {
            enabled: false
        },
        title: {
            text: null
        },
        subtitle: {
            text: null
        },
        xAxis: {
            id: "x-axis",
            type: "datetime",
            gridLineWidth: 1,
            gridZIndex: 2,
            tickLength: 0
        },
        yAxis: {
            id: "y-axis",
            title: {
                text: null
            },
            /* warning area */
            plotBands: [{
                color: "#F6AABB",
                from: 7.5,
                to: 10,
                zIndex: 1
            }],
            endOnTick: false,
            min: 0,
            softMax: 10,
            tickInterval: 2.5,
            color: "#F6AABB"
        },
        series: [
            {
                type: "line",
                id: "risk-rating",
                name: "Risk Rating",
                color: "#2699C4",
                zIndex: 4,
                lineWidth: 3,
                data: []
            },
            {
                type: "area",
                id: "series-warning-area",
                name: "Warning Area",
                color: "#F6AABB",
                events: {
                    legendItemClick: () => {
                      return false;
                    }
                }
            },
            // {
            //     type: "line",
            //     id: "series-industry-average",
            //     marker: {
            //         radius: 2
            //     },
            //     name: "Industry Average",
            //     color: "#B3A995",
            //     dashStyle: "ShortDash",
            //     zIndex: 3,
            //     data: []
            // }
        ],
        tooltip: {
            headerFormat: `<tspan style="font-size: 0.8em;">{point.key}</tspan><tspan class="highcharts-br" dy="15" x="8">&ZeroWidthSpace;</tspan>`
        }
    });


    useEffect(() => {
        CompanyWatchApi.post(`/`, {
            company_id: id
        }).then((res) => {
            const cwData = (res.data.score || []).reverse();

            const options = { ...chartOptions };

            options.xAxis.categories = [];
            options.series.map(s => s.data = []);

            cwData.map(entry => {
                options.xAxis.categories.push(Highcharts.dateFormat('%b %Y', new Date(entry.scoreDate)));
                options.series[0].data.push(entry.riskRating || null);
            });

            setChartOptions(options);
            setData(res.data);
        }, (reason) => {
            console.error(reason);
        })
    }, [id]);

    return (
        <div className="cwchart">
            <h3 className='h2 mt-4 mb-4'>
                Company Watch Risk Rating
            </h3>

            <div className="header">
                <div className="header__logo">
                    <div className="logo">
                        <img src={CompanyWatchLogo} alt="CompanyWatch"/>
                    </div>
                </div>

                <div className="stats">
                    <div className="statsItem">
                        <span className="statsItem__value">{data?.score[4]?.probabilityOfDistress1Year ? `${data.score[4]?.probabilityOfDistress1Year}%` : '-'}</span>
                        <span className="statsItem__label">1YR PoD</span>
                    </div>
                    <div className="statsItem">
                        <span className="statsItem__value">{data?.score[4]?.riskRating || '-'}</span>
                        <span className="statsItem__label">RISK RATING</span>
                    </div>
                    <div className="statsItem">
                        <span className="statsItem__value">{data?.credit_limits?.currency || ''} {data.credit_limits?.currentCreditLimit ? toHumanString(data.credit_limits?.currentCreditLimit) : '-'}</span>
                        <span className="statsItem__label">CREDIT LIMIT</span>
                    </div>
                    <div className="statsItem">
                        <span className="statsItem__value">{data?.contract_limits?.currency} {data.contract_limits?.currentContractLimit ? toHumanString(data.contract_limits?.currentContractLimit) : '-'}</span>
                        <span className="statsItem__label">CONTRACT LIMIT</span>
                    </div>
                </div>
            </div>

            <div className="info-text">
                <h3>Risk Rating</h3>
                <p>
                    Risk Rating by Company Watch places the company into one of ten risk categories, with 1 indicating the lowest Probability of Distress and 10 the highest. Each successive step in the Risk Rating scale indicates a doubling of the degree of risk. The Company Watch PoD&reg; (Probability of Distress) gives the statistical likelihood of a company failing or undergoing a major financial reconstruction in the next 12 months.
                </p>
            </div>

            <HighchartsReact
                highcharts={Highcharts}
                options={chartOptions}
            />

            <div className="info-text">
                <p>
                    A company falls into the Company Watch Warning Area if it has a risk score of 7 or more. These companies are showing similar traits to companies that have failed in the past and have a higher probability of suffering distress themselves.
                </p>
                <p>To see the full Company Watch financial risk analysis of this company and millions of other UK companies, <a href="https://referrals.companywatch.net/n8viLe" target="_blank" rel="noreferrer" className="text-underline"><strong>try for free today</strong></a>!</p>

                <h3>Credit Limit</h3>
                <p>The Credit Limit is a benchmark unsecured trade limit set by Company Watch, that could be used as a starting point when considering the amount of credit that should be extended to this company by a single supplier of goods, services or finance.</p>
                <p>The Credit Limit is only a benchmark guide (not a rigid specification) and a supplier should always carry out additional research, particularly when considering extending credit in respect of relatively larger sums and/or extending credit to companies in the Warning Area.</p>
                <p>The calculation gives consideration to the company's size, level of trading activity, the degree to which it is already exposed short term to its creditors, its ability to repay them and its working capital position. It uses the H-Score, the measure of the company's financial strength including cash flow measures and the Probability of Distress. It also takes into account the number and value of any County Court Judgments recently served against the company and the period of time for which the company has been trading.</p>
                <p>The Credit Limit assumes average circumstances, for example a 60-day period before payment is received. It has not been specifically customised, nor does it address a supplier's own circumstances such as its ability to bear losses, its profit margins and its appetite for risk.</p>

                <h3>Contract Limit</h3>
                <p>The Contract Limit, determined by Company Watch is the recommended maximum value amount that a particular company can supply without causing a strain on the company's existing resources.</p>
                <p>The Contract Limit takes into account the level of financial risk (including the H-Score rating), the company's age, any outstanding CCJs as well as the industry sector. The Contract Limit is calculated as a percentage of a blend of Sales and Total assets. Note where the Sales number is not provided, this is estimated using extrapolation of information provided in the Balance Sheet.</p>
                <p>The Contract Limit for a given entity is typically much higher than the Credit Limit (which measures the recommended maximum credit that should be extended to a company). The maximum possible Contract Limit for any company is £200 Million.</p>

            </div>
        </div>
    );
}

export default CWChart;
