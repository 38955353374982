// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Button {
  border-radius: 30px;
  min-width: 9.375rem;
}

.primary {
  background: #317af6;
  border: none;
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/components/common/Button.scss","webpack://./src/assets/scss/helpers/_variable.scss"],"names":[],"mappings":"AAEA;EACE,mBAAA;EACA,mBAAA;AADF;;AAIA;EACE,mBCFK;EDGL,YAAA;EACA,WAAA;AADF","sourcesContent":["@import '../../assets/scss/helpers/component';\n\n.Button {\n  border-radius: 30px;\n  min-width: rem(150px);\n}\n\n.primary {\n  background: $blue;\n  border: none;\n  color: $white;\n}\n","// Fonts colors\n$primary: #415a77;\n$secondary: #2e2e3a;\n$header-background: #415a77;\n$primary-green: #2afca3;\n$light-grey: #f1f4fb;\n$blue: #317af6;\n$light-blue: #89b8f1;\n$light-green: #8ebbf1;\n$border-light: #d3e1fd;\n$white: #fff;\n$sky-blue: #cad8f2;\n$green: #3cc221;\n$red: #f42b03;\n$golden: #ffe13e;\n$pink: #fd6383;\n$dark-blue: #405873;\n$dark-blue-active: #35485e;\n$pale-grey: #f8fafd;\n$table-border: #c5d5f4;\n$highlight-text-color: #2b2b2b;\n$black: #000000;\n$accent4: #fab723;\n$maroon: #bb0611;\n$dark-blue-link: #263546;\n\n\n$gbp-rich-green: #003825;\n$gbp-electric-green: #00ff7f;\n$gbp-blue: #00a4ff;\n\n$table-border: $gbp-electric-green;\n\n$font-family-base: 'MADETOMMY', Arial, sans-serif;\n$font-family-alt: 'HeadingPro', Arial, sans-serif;\n\n$grid-breakpoints: (\n  xs: 0,\n  sm: 576px,\n  md: 768px,\n  lg: 992px,\n  xl: 1200px,\n  // xxl: 1440px,\n  // xxxl: 1600px\n);\n$container-max-widths: (\n  sm: 540px,\n  md: 720px,\n  lg: 960px,\n  xl: 1140px,\n  // xxl: 1440px,\n  // xxxl: 1600px\n);\n\n\n$zi-header: 1000;\n$zi-cookie-overlay: 1100;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
