import { LIMIT } from '../components/constants/pagination';
import Api from './Api';
import { getOffset } from '../helpers/getOffset';
// eslint-disable-next-line import/prefer-default-export
export const getQueryData = (name, page) => {
  return Api.get('/companies', {
    params: {
      name,
      limit: LIMIT,
      ...(page ? { offset: getOffset(page, LIMIT) } : {})
    }
  });
};

export const getCompanyDetailsService = (number) => {
  return Api.get(`/companies/${number}`);
};

export const getAverageTimeToPayQuartileService = () => {
  return Api.get('/companies/average-time-to-pay/quartiles');
};

export const getPaidOutsideQuartileService = () => {
  return Api.get('/companies/paid-outside-agreed-terms/quartiles');
};

export const getReportingDataService = (number) => {
  return Api.get('/reports', { params: { 'company-number': number } });
};
