import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { getFormattedAddress } from '../../helpers/address';

const ResultRow = ({ row, rowClasses = '' }) => {
  const history = useHistory();
  return (
    <div
      className={`Search-Row Search-Row-Table ${rowClasses}`}
      role="button"
      onClick={() => history.push(`/company/${row.number}`)}
      aria-hidden
    >
      <Col className="Col--name ListItemContainer-MainXS">
        <strong>{row.name}</strong>
      </Col>
      <Col className="Col--reg ListItemContainer-XS">
        {row.number}
      </Col>
      <Col className="Col--address ListItemContainer-XS">
        { getFormattedAddress(row['registered-address']) }
      </Col>
      <Col className="Col--cta ListItemContainer-XS">
        <FontAwesomeIcon className="Button-Icon" icon={faArrowRight} />
      </Col>
    </div>
  );
};

export default ResultRow;
