import React, { useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import ReactSpeedometer from 'react-d3-speedometer';

const Speedometer = ({ chartValue, scale, ...props }) => {
  const ref = useRef(null);
  const [chartWidth, setChartWidth] = useState(0);
  const [chartHeight, setChartHeight] = useState(0);
  const [ringWidth, setRingWidth] = useState(0);

  useEffect(() => {
    const width = ref.current ? ref.current.offsetWidth : 0;
    if (width > 700) {
      setChartWidth(width - 175);
      setChartHeight(width / 2.5);
      setRingWidth(50);
    } else if (width > 600) {
      setChartWidth(width - 100);
      setChartHeight(width / 2.25);
      setRingWidth(40);
    } else {
      setChartWidth(width - 40);
      setChartHeight(width / 2);
      setRingWidth(25);
    }
  }, [ref.current?.offsetWidth]);

  return (
    <Row className="justify-content-center mb-5">
      <Col md="12" ref={ref} className="text-center p-0 m-0">
        {chartWidth && (
          <ReactSpeedometer
            {...props}
            ringWidth={ringWidth}
            width={chartWidth}
            height={chartHeight}
            needleTransitionDuration={1000}
            paddingVertical={20}
            paddingHorizontal={20}
            currentValueText=""
          />
        )}
      </Col>
      <div className="ChartValue-Container">
        <span className="Heading">{chartValue}</span> {scale}
      </div>
    </Row>
  );
};

export default Speedometer;
