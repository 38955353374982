import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
// import { ReactComponent as SearchIcon } from '../../assets/images/search.svg';
import Button from './Button';
import './SearchInput.scss';

const SearchInput = ({ query = '', id = '', placeholder }) => {
  const [searchText, setQuery] = useState(query);
  const history = useHistory();
  const getSearchList = (e) => {
    e.preventDefault();
    if (!searchText) {
      return;
    }
    history.push(`/search?query=${searchText.replace('&', '%26')}`);
  };
  return (
    <Form className={`Search-bar ${id === 'header' ? 'Search-bar-fluid' : '' }`} onSubmit={getSearchList}>
      <Form.Group>

        <Form.Control
          id={`${id}-searchText`}
          placeholder={placeholder || " "}
          onChange={(e) => setQuery(e.target.value)}
          value={searchText}
          data-testid={`${id}-searchText`}
        />
        <Form.Label htmlFor={`${id}-searchText`}>
          Check your client's payment record
        </Form.Label>

      </Form.Group>
      <Button
        type="submit"
        className="Search-Button"
        onClick={getSearchList}
        data-testid={`${id}-searchButton`}
      >
        <FontAwesomeIcon className="Button-Icon" icon={faSearch} />
      </Button>
    </Form>
  );
};

export default SearchInput;
