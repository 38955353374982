/* eslint-disable */
import React from 'react';
import './SocialLinks.scss';

const SocialLinks = () => {
    return <div className="w-socials color_text shape_rounded style_default hover_fade" >
        <div className="w-socials-list">
            <div className="w-socials-item facebook">
                <a target="_blank" rel="nofollow" href="https://www.facebook.com/GoodBusinessPays" className="w-socials-item-link" title="Facebook" aria-label="Facebook">
                    <span className="w-socials-item-link-hover">
                    </span>
                    <i className="fab fa-facebook">
                    </i>
                    <span className="ripple-container">
                    </span>
                </a>
                <div className="w-socials-item-popup">
                    <span>Facebook</span>
                </div>
            </div>
            <div className="w-socials-item twitter">
                <a target="_blank" rel="nofollow" href="https://twitter.com/goodbizpays" className="w-socials-item-link" title="Twitter" aria-label="Twitter">
                    <span className="w-socials-item-link-hover">
                    </span>
                    <i className="fab fa-twitter">
                    </i>
                    <span className="ripple-container">
                    </span>
                </a>
                <div className="w-socials-item-popup">
                    <span>Twitter</span>
                </div>
            </div>
            <div className="w-socials-item linkedin">
                <a target="_blank" rel="nofollow" href="https://www.linkedin.com/company/good-business-pays/" className="w-socials-item-link" title="LinkedIn" aria-label="LinkedIn">
                    <span className="w-socials-item-link-hover">
                    </span>
                    <i className="fab fa-linkedin">
                    </i>
                    <span className="ripple-container">
                    </span>
                </a>
                <div className="w-socials-item-popup">
                    <span>LinkedIn</span>
                </div>
            </div>
            <div className="w-socials-item youtube">
                <a target="_blank" rel="nofollow" href="https://www.youtube.com/channel/UC1P2bL_CN-Tfv8CWvcuXhJw" className="w-socials-item-link" title="YouTube" aria-label="YouTube">
                    <span className="w-socials-item-link-hover">
                    </span>
                    <i className="fab fa-youtube">
                    </i>
                    <span className="ripple-container">
                    </span>
                </a>
                <div className="w-socials-item-popup">
                    <span>YouTube</span>
                </div>
            </div>
        </div>
    </div>
}

export default SocialLinks;
