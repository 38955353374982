import axios from 'axios';


const CompanyWatchApi = axios.create({
  baseURL: process.env.REACT_APP_GBP_COMPANYWATCH_API_URL
});




export default CompanyWatchApi;
