import React from 'react';
import SearchComponent from '../components/SearchList/SearchComponent';
import HomeLayout from '../layouts/HomeLayout';
import { ReactComponent as Logo } from '../assets/images/gbp_logo.svg';
import './HomePage.scss';
// import Survey from '../components/common/Survey';

const HomePage = () => {
  return (
    <div className="homepage">
      <HomeLayout>
        <Logo
          className="homepage__logo"
          role="button"
          aria-hidden
          onClick={() => window.location.href = 'https://goodbusinesspays.com/'}
        />

        <h1 className="text-center">Payment performance report</h1>

        <p className="text-center">See how your customers and prospects are performing</p>

        <div className="homepage__searchWrapper">
          <SearchComponent placeholder="Enter a name or registration number" id="homepage" />
        </div>
      </HomeLayout>
    </div>
  );
};
export default HomePage;
