import { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import './CookieModal.scss';

const CookieModal = () => {
  useEffect(() => {
    const tagManagerArgs = {
      gtmId: process.env.REACT_APP_GTM
    };
    TagManager.initialize(tagManagerArgs);
  }, []);

  return '';
};

export default CookieModal;
