import React from 'react';
import { Col, Row } from 'react-bootstrap';
import speedometerColors from '../constants/speedometerColors';
import Speedometer from './Speedometer';

const SpeedometerContainer = ({
  paidOutsideQuartile,
  averageQuartile,
  averageTimeToPay,
  late
}) => {
  return (
    <>
      <div className="Performance-Report text-center">
        <i className="fas fa-analytics Icon" />

        <h2 className="h1 mb-2 mt-3">Performance Report</h2>

        <p>
          A comparison with other UK companies providing payment data
        </p>
      </div>

      <Row className="Speedometers">
        <Col xl="6" xs="12">
          <div className="Speedometer-Heading">
            Average Time to Pay an Invoice
          </div>

          <Speedometer
            segments={5}
            segmentColors={speedometerColors}
            customSegmentStops={[
              0,
              ...Object.values(averageQuartile)
                .filter((val, idx) => idx !== 3)
                .map((q) => Math.round(q)),
              120,
              140
            ]}
            value={averageTimeToPay > 135 ? 135 : averageTimeToPay}
            minValue={0}
            maxValue={140}
            chartValue={averageTimeToPay}
            scale="Days"
            customSegmentLabels={[
              ...Object.values(averageQuartile)
                .filter((val, idx) => idx !== 3)
                .map((q) => ({
                  text: Math.round(q).toString(),
                  position: 'OUTSIDE',
                  color: 'black'
                })),
              {
                text: '120',
                position: 'OUTSIDE',
                color: 'black'
              },
              {
                text: `> 120`,
                position: 'OUTSIDE',
                color: 'black'
              }
            ]}
          />
        </Col>
        <Col xl="6" xs="12">
          <div className="Speedometer-Heading">
            Volume of Invoices Paid Late
          </div>
          <Speedometer
            segments={5}
            segmentColors={speedometerColors}
            customSegmentStops={[
              0,
              ...Object.values(paidOutsideQuartile).map((p) => p / 100),
              1
            ]}
            value={late / 100}
            minValue={0}
            maxValue={1}
            chartValue={`${late}%`}
            scale="Late"
            valueFormat=".0%"
          />
        </Col>
      </Row>

      <Row>
        <Col md={{ span: 6, offset: 3 }}
          style={{ flexDirection: 'column' }}
        >
          {/*
          <div className="Speedometer-PlaceHolder" />

          <div className="Speedometer-PlaceHolderText">
            <span>Top 25%</span>
            <strong className="Desktop-Only">Performance v Top 1,000 Companies</strong>
            <strong className="Mobile-Only">Performance v Top 1,000 Companies</strong>
            <span>Bottom 25%</span>
          </div>
          */}
        </Col>
      </Row>
    </>
  );
};

export default SpeedometerContainer;
