import React from 'react';
import './ThankYou.scss';

const ThankYou = () => {
  return (
    <div className="thankYou p-4">
      <div>
        <h1 className="h2">Thank you for joining us</h1>
        <p>We'll be in touch soon</p>
      </div>
    </div>
  );
};

export default ThankYou;
