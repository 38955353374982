import React from 'react';
import SearchComponent from '../SearchList/SearchComponent';
import { ReactComponent as Logo } from '../../assets/images/gbp_logo.svg';

const HeaderWithSearch = ({ query }) => {
  return (
    <div className="Header WithSearch">
      <div className="container">
        <Logo
          className="logo-placeHolder Header-Logo"
          role="button"
          aria-hidden
          onClick={() => window.location.href = 'https://goodbusinesspays.com/'}
        />

        <SearchComponent query={query} id="header" />
      </div>
  </div>);
};
export default HeaderWithSearch;
