import React from 'react';
import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';

const GradientLineChart = ({ companyReporting }) => {
  const values = [
    {
      width: companyReporting['shortest-standard-payment-period'],
      background: '#00a4ff',
      label: companyReporting['longest-standard-payment-period']
        ? 'Min standard terms'
        : 'Standard terms'
    },
    {
      width: companyReporting['average-time-to-pay'],
      background: '#ffe13e',
      label: 'Standard terms'
    },
    {
      ...(companyReporting['longest-standard-payment-period']
        ? {
            width: companyReporting['longest-standard-payment-period'],
            background: '#fd6383',
            label: 'Max payment terms'
          }
        : {})
    }
  ];
  const renderTooltip = ({ value, ...props }) => (
    <Tooltip id={`${value}-tooltip`} {...props} className="px-5">
      <p className="mb-0 py-2 px-5">{value > 60 ? '60+' : value} days</p>
    </Tooltip>
  );
  const gradientColors = values
    .filter((v) => v?.width >= 0)
    .reduce((acc, val) => (acc += `,${val.background}`), '');
  return (
    <>
      <h3 className="mt-lg text-center mt-lg mb-0">
        What payment terms are offered?
      </h3>

      <p className="text-center mb-4">
        split out into more detail by typical payment periods
      </p>

      <div className="Gradient-Container">
        <div className="d-flex">
          <div
            className="Gradient-Line d-flex"
            style={{
              background: `linear-gradient(to right ${gradientColors})`
            }}
          >
            {values.map((val, idx) => (
              <OverlayTrigger
                placement="top"
                key={idx.toString()}
                overlay={(props) => renderTooltip({ value: val.width, ...props })}
              >
                <div
                  style={{
                    background: val.background,
                    left: `${((val.width > 60 ? 70 : val.width) / 75) * 100}%`
                  }}
                  className="Gradient-Round "
                />
              </OverlayTrigger>
            ))}
          </div>
        </div>
      </div>


      <small className="d-block text-center mt-1">
        <strong>Days</strong>
      </small>

      <Row className="d-flex justify-content-center mt-4 mb-5">
        {values
          .filter((v) => v?.width >= 0)
          .map((value, idx) => {
            return (
              <Col
                key={idx.toString()}
                md={4}
                xs={10}
              >
                <div className="Heading-Column">
                  <div
                    className="Square-Indicator"
                    style={{ background: value.background }}
                  />
                  <span className="mt-1 Chart-Tooltip">{`${value.width} days (${value.label})`}</span>
                </div>
              </Col>
            );
          })}
      </Row>
    </>
  );
};

export default GradientLineChart;
