import React from 'react';
import { Col } from 'react-bootstrap';
import ResultRow from './ResultRow';

const ResultsComponent = ({ title, data, rowClasses }) => {
  return (
    <div className="Row-Margin">
      <h2 className="resultsHeading">{title}</h2>

      {data.length > 1 && (
        <div className={`Search-Row Search-Row-Header ${rowClasses}`}>
          <Col className="Col--name ListItemContainer-MainXS">
            Company name
          </Col>
          <Col className="Col--reg ListItemContainer-XS">
            Reg. number
          </Col>
          <Col className="Col--address ListItemContainer-XS">
            Address
          </Col>
          <Col className="Col--cta ListItemContainer-XS" />
        </div>
      )}

      {data.map((row, index) => {
        return (
          <ResultRow key={index.toString()} rowClasses={rowClasses} row={row} />
        );
      })}
    </div>
  );
};

export default ResultsComponent;
