import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import Footer from '../components/common/Footer';
import Header from '../components/common/Header';
import './MainLayout.scss';

const MainLayout = ({
  children,
  header = true,
  CustomHeader,
  backUrl = false,
  headerTitle = '',
  CustomComponent
}) => {
  const history = useHistory();
  return (
    <div className="Layout-Container">
      {header ? <Header title={headerTitle} /> : <CustomHeader />}

      <div className="container">
        {backUrl && (
          <>
            <Button
              variant="link"
              onClick={() => history.goBack()}
              className="Header-Link"
            >
              <FontAwesomeIcon
                className="Button-Icon mr-2"
                icon={faArrowLeft}
              />{' '}
              <strong>Go back</strong>
            </Button>
          </>
        )}
        {children}
      </div>

      {CustomComponent && <CustomComponent />}

      <Footer />
    </div>
  );
};

export default MainLayout;
