export const getFormattedAddress = (address) => {
    const separator = ', ';
    const result = `
        ${address['care-of'] != null ? `c/o ${address['care-of']}${separator}` : ''}
        ${address['address-line-1'] != null ? `${address['address-line-1']}${separator}` : ''}
        ${address['address-line-2'] != null ? `${address['address-line-2']}${separator}` : ''}
        ${address['po-box'] != null ? `PO Box ${address['po-box']}${separator}`: ''}
        ${address['postal-code'] != null ? `${address['postal-code']}${separator}` : ''}
        ${address.locality != null ? `${address.locality}${separator}` : ''}
        ${address.region != null ? `${address.region}${separator}` : ''}
        ${address.country != null ? `${address.country}${separator}` : ''}
    `.trim();

    return result.substr(0, result.length - 1);
}
