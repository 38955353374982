import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

const Loader = () => {
  return (
    <div className="m-8 text-center">
      <FontAwesomeIcon className="Button-Icon fa-spin" icon={faSpinner} />
    </div>
  );
};

export default Loader;
