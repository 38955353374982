import React from 'react';

const DescriptionRow = ({ title, value }) => {
  return (
    <>
      <p className="mb-0"> {title}</p>
      <p>{value}</p>
    </>
  );
};

export default DescriptionRow;
