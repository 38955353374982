import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import moment from 'moment';
// import Divider from '../components/common/Divider';
import CompanyDescription from '../components/Details/CompanyDescription';
// import DateRangeComponent from '../components/Details/DateRangeComponent';
import GradientLineChart from '../components/Details/GradientLineChart';
import RowChart from '../components/Details/RowChart';
import SpeedometerContainer from '../components/Details/SpeedometerContainer';
import MainLayout from '../layouts/MainLayout';
import {
  getAverageTimeToPayQuartileService,
  getCompanyDetailsService,
  getPaidOutsideQuartileService,
  getReportingDataService
} from '../services/listing.service';
import './DetailsPage.scss';
import CWChart from '../components/CompanyWatch/chart';

const DetailsPage = () => {
  // const [feedback, setFeedback] = useState();
  const [averageQuartile, setAverageQuartile] = useState();
  const [paidOutsideQuartile, setPaidOutsideQuartile] = useState();
  const [companyDetails, setCompanyDetails] = useState();
  const [companyReporting, setCompanyReporting] = useState();
  const [error, setError] = useState();
  const { id } = useParams();

  const getCompanyDetails = async (companyNumber) => {
    try {
      const response = await getCompanyDetailsService(companyNumber);
      setCompanyDetails(response.data);
    } catch (e) {
      setError(true);
    }
  };

  const getAverageTimeToPayQuartile = async () => {
    try {
      const response = await getAverageTimeToPayQuartileService();
      setAverageQuartile(response.data);
    } catch (e) {
      setError(true);
    }
  };

  const getPaidOutsideQuartile = async () => {
    try {
      const response = await getPaidOutsideQuartileService();
      setPaidOutsideQuartile(response.data);
    } catch (e) {
      setError(true);
    }
  };

  const getReportingData = async (companyNumber) => {
    try {
      const response = await getReportingDataService(companyNumber);
      response.data.sort((a, b) => {
        return new Date(b['end-date']) - new Date(a['end-date']);
      });
      setCompanyReporting(response.data[0]);
    } catch (e) {
      setError(true);
    }
  };

  useEffect(() => {
    setError(false);
    getCompanyDetails(id);
    getAverageTimeToPayQuartile();
    getPaidOutsideQuartile();
    getReportingData(id);
  }, [id]);

  return (
    <MainLayout headerTitle={companyDetails?.name} backUrl>
      {error && (
        <Alert variant="danger" className="text-center mt-5">
          Something went wrong!
        </Alert>
      )}
      {companyDetails && (
        <>
          <h1 className="Company-Details-Title">{companyDetails?.name}</h1>

          <p className="text-center">
            Prompt Payment Report for {
              companyReporting && moment(companyReporting['start-date'], 'YYYY-MM-DD').format('D MMMM YYYY')
            } to {
              companyReporting && moment(companyReporting['end-date'], 'YYYY-MM-DD').format('D MMMM YYYY')
            }
          </p>

          <CompanyDescription companyDetails={companyDetails} />
        </>
      )}
      {/* <FeedBackComponent
        feedback={feedback}
        setFeedback={setFeedback}
        showButton={false}
        title="What’s your experience with this company?"
        descriptionText="Click the thumbs up or thumbs down button to tell us more about your payment experience
        with this business. You’ll be helping other SME businesses like yours to understand more
        about how being paid works and we’ll be sure to never reveal your identity when reviewing."
      /> */}

      {companyReporting &&
        companyReporting['payments-made?'] &&
        paidOutsideQuartile &&
        averageQuartile &&
        companyReporting &&
        companyReporting?.['average-time-to-pay'] && (
          <SpeedometerContainer
            averageTimeToPay={companyReporting['average-time-to-pay']}
            paidOutsideQuartile={paidOutsideQuartile}
            averageQuartile={averageQuartile}
            late={companyReporting['paid-outside-agreed-terms']}
          />
        )}
      {companyReporting && companyReporting['payments-made?'] && (
        <RowChart companyReporting={companyReporting} />
      )}
      {companyReporting && companyReporting['payments-made?'] && (
        <GradientLineChart companyReporting={companyReporting} />
      )}
      {/* {companyReporting && (
        <DateRangeComponent
          startDate={companyReporting['start-date']}
          endDate={companyReporting['end-date']}
          reg={id}
          paymentsMade={companyReporting['payments-made?']}
        />
      )} */}

      <div>
        <div className="Company-Container Gradient-Container text-center mb-5">
          <h2 className="h1 mb-2 mt-5">
            Risk Profile
          </h2>

          <CWChart />
        </div>
      </div>
    </MainLayout>
  );
};

export default DetailsPage;
