import React from 'react';
import MainLayout from './MainLayout';

const HomeLayout = ({ children, CustomComponent }) => {
  return (
    <MainLayout
      header={false}
      CustomComponent={CustomComponent}
      CustomHeader={() => <></> }
    >
      {children}
    </MainLayout>
  );
};

export default HomeLayout;
