import React from 'react';
import SocialLinks from './SocialLinks';

const Footer = () => {
  return (
    <footer className="Footer">
      <section className="container">
        <div className="Footer-Social no-touch">
          <SocialLinks />
        </div>

        <p className="Footer-Terms">For help and support, contact <a href="mailto:info@goodbusinesspays.com">info@goodbusinesspays.com</a>
        </p>
        <p className="Footer-Terms">&copy; Copyright 2024, Good Business Pays CIC | <a href="https://goodbusinesspays.com/privacy-policy/">Privacy Policy</a>&nbsp;|&nbsp;<a href="https://goodbusinesspays.com/terms-of-use/">Terms of Use</a>
          <br />
          Contains public sector information licensed under the Open Government Licence v3.0.</p>
      </section>
    </footer>
  );
};

export default Footer;
