import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import HeaderWithSearch from '../components/common/HeaderWithSearch';
import Loader from '../components/common/Loader';
import PaginationFooter from '../components/SearchList/PaginationFooter';
import ResultsComponent from '../components/SearchList/ResultsComponent';
import { useQuery } from '../helpers/useQuery';
import MainLayout from '../layouts/MainLayout';
import { getQueryData } from '../services/listing.service';
import './SearchList.scss';

const SearchList = () => {
  const query = useQuery().get('query');
  const [data, setData] = useState([]);
  const [topRecord, setTopRecord] = useState();
  const [loading, setLoading] = useState(false);
  // const [feedback, setFeedback] = useState();
  const [total, setTotal] = useState();
  const [page, setPage] = useState();

  const getSearchedData = useCallback(async () => {
    if (!query || loading) return;
    setLoading(true);
    try {
      const response = await getQueryData(query);
      if (!response.data.total > 0) {
        setData([]);
        setTopRecord(null);
        setTotal(response.data.total);
        return;
      }
      const [firstRecord] = response.data.results.splice(0, 1);
      setTopRecord(firstRecord);
      setData(response.data.results);
      setTotal(response.data.total);
      setPage(0);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, [query]);

  useEffect(() => {
    getSearchedData();
  }, [getSearchedData]);

  const handlePaginationChange = async ({ selected }) => {
    if (!query || loading) return;
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    setLoading(true);
    setPage(selected);
    try {
      const response = await getQueryData(query, selected);
      if (!response.data.total > 0) {
        setData([]);
        return;
      }
      if (selected === 0) {
        response.data.results.splice(0, 1);
        setData(response.data.results);
      } else {
        setData(response.data.results);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <MainLayout
      backUrl
      header={false}
      CustomHeader={() => <HeaderWithSearch query={query} />}
    >
      {loading ? (
        <Loader />
      ) : data.length || topRecord ? (
        <>
          {topRecord && (
            <ResultsComponent title={`Top Result for "${query}"`} data={[topRecord]} rowClasses="Search-Row-Top" />
          )}
          {data.length > 0 && (
            <ResultsComponent
              title="More Results"
              data={data}
              rowClasses="Border-Light"
            />
          )}
        </>
      ) : (
        query && (
          <Row className="justify-content-center mb-5">
            <Col md={12} xs={12}>
              <p className="Search-Heading mb-0">
                Sorry, no results found for {query}
              </p>
              <hr />
              <p className="Lighter-ColorText">
                We use data from the UK Government to show how companies
                perform, however not all companies are required to report on
                their payment performance.
              </p>
              <p className="Lighter-ColorText">
                We are planning to enhance this site by adding crowd-sourced
                information in the future. If you are interested, would like to
                contribute, or can give us some early insights based on your
                experience, please complete the very short survey below.
              </p>
            </Col>
          </Row>
        )
      )}

      {total && total > 10 ? (
        <PaginationFooter
          handlePaginationChange={handlePaginationChange}
          total={total}
          page={page}
        />
      ) : null}
    </MainLayout>
  );
};

export default SearchList;
